import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import { makeStyles } from '@mui/styles';
import {
  Modal,
  Typography
} from '@mui/material';
import PolylineIcon from '@mui/icons-material/Polyline';
import { GoogleMap, DrawingManager, Polygon } from '@react-google-maps/api';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 800,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius:15,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));


export default function Zones() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editZone
  } = api;

  const columns = [
    { title: t('zone_name'), field: 'zone_name',
      headerStyle:isRTL==='rtl'?{paddingRight: 170}:{ paddingLeft: 170},
      cellStyle:isRTL ==='rtl'? {paddingRight: 170, textAlign: 'center' }:{ paddingLeft:170, textAlign: 'center' }
    },
    { title: t('zone_surge'), field: 'zone_surge', type: 'numeric', initialEditValue: 0},
    { title: t('active'),  field: 'active', type:'boolean', initialEditValue: true,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'center'}},
    { title: t('zone_path'), field: 'zone_path', editable: 'never',
      render: rowData => rowData.zone_path? <span>{rowData.zone_path.slice([0], [2]).join(",")}...</span>:null
    }
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const zonelistdata = useSelector(state => state.zonelistdata);
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false);
  const [rowIndex,setRowIndex] = useState();
  const classes = useStyles();
  const rootRef = useRef(null);
  const [polygon, setPolygon] = useState();
  const [mylocation, setMylocation] = useState(null);
   
  useEffect(() => {
    if (zonelistdata.zones) {
      setData(zonelistdata.zones);
    }else{
      setData([]);
    }
  }, [zonelistdata.zones]);

  const handleClose = () => {
    setOpen(false);
  }

  const handlePolygonComplete = polygon => {
    const arr = [];
    let zone = data[rowIndex];
    polygon.getPaths().getArray().map(path => path.getArray().forEach((position) => arr.push([ position.lat(), position.lng()])));
    zone['zone_path'] = arr;
    dispatch(editZone(zone, "Edit"));
    setOpen(false);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    zonelistdata.loading ? <CircularLoading /> :
    <div ref={rootRef}>
      <MaterialTable
        title={t('zones')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          exportButton: true,
          pageSize: 10,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
          editable:{
            backgroundColor: colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
          },
          cellStyle:{  textAlign:'center'},
          headerStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: colors.SECONDARY ,
            color:colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace',
            textAlign:'center'
          },
        }}
        localization={{body:{
          addTooltip: (t('add')),
          deleteTooltip: (t('delete')),
          editTooltip: (t('edit')),
          emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
            }, 
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                if(!(newData && newData.zone_name && newData.zone_surge)){
                  alert(t('no_details_error'));
                  reject();
                }else{
                  if(newData.zone_surge < 0 ){
                    alert(t('given_value_must_be_positive'));
                    reject();
                  } else{
                    dispatch(editZone(newData, "Add"));
                    resolve();
                  }
                }
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                if(!(newData && newData.zone_name && newData.zone_surge)){
                  alert(t('no_details_error'));
                  reject();
                }else {
                  if(newData.zone_surge < 0 ){
                    alert(t('given_value_must_be_positive'));
                    reject();
                  } else{
                    dispatch(editZone(newData, "Update"));
                    resolve()
                  }
                }
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editZone(oldData, "Delete"));
              }, 600);
            }),
        } : null}
        actions={[
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <PolylineIcon />
                <Typography variant="subtitle2" style={{padding: 5}}>{t('edit_zone_path')}</Typography>
            </div>,
            onClick: (event, rowData) => {
              setRowIndex(rowData.tableData.id);
              setOpen(true);
              if(rowData.zone_path && rowData.zone_path.length>0){
                let arr = [];
                for(let i=0;i<rowData.zone_path.length;i++){
                  const path = rowData.zone_path[i];
                  arr.push(new window.google.maps.LatLng(path[0], path[1]));
                }
                setPolygon(arr);
                let obj = JSON.stringify(arr[0]);
                let obj2 = JSON.parse(obj);
                setMylocation({
                  lat: obj2.lat,
                  lng: obj2.lng
                })
              } else{
                setMylocation(null);
                setPolygon();
              }
            }
          })
        ]}
      />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <GoogleMap
              zoom={polygon? 8 : 2}
              center={{ lat: mylocation? mylocation.lat : 30.00, lng: mylocation? mylocation.lng : 31.00 }}
              mapContainerStyle={{ height: '100%' }}
          >
              <DrawingManager
                  drawingMode={'polygon'}
                  options={{drawingControl: false}}
                  onPolygonComplete={handlePolygonComplete}
              />
              {polygon && polygon.length > 0 && (
                <Polygon paths={polygon} />
              )}
          </GoogleMap>
        </div>
      </Modal>
    </div>
  );
}
