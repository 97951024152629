import {
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_COUNTRIES_FAILED,
    FETCH_COUNTRIES_STRING_SUCCESS,
    EDIT_COUNTRY
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, remove, push } from "firebase/database";
  
  export const fetchCountryData = () => (dispatch) => {
    const {
      countryRef
    } = firebase;
    dispatch({
      type: FETCH_COUNTRIES,
      payload: null
    });
    onValue(countryRef, snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        let countryRestrictStr = "";
        for(let i=0;i<arr.length;i++){
          countryRestrictStr += "country:" + arr[i].country_code.toLowerCase();
          if(i<arr.length - 1){
            countryRestrictStr += "|";
          }
        }
        dispatch({
          type: FETCH_COUNTRIES_STRING_SUCCESS,
          payload: countryRestrictStr
        });
        dispatch({
          type: FETCH_COUNTRIES_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_COUNTRIES_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_countries
        });
      }
    });
  };
  
  export const editCountry = (country, method) => async (dispatch) => {
    const {
      countryRef, 
      countryEditRef,
    } = firebase;
    dispatch({
      type: EDIT_COUNTRY,
      payload: { method, country }
    });
    if (method === 'Add') {
      push(countryRef, country);
    } else if (method === 'Delete') {
      remove(countryEditRef(country.id));
    }else {
      update(countryEditRef(country.id),country);
    }
  }