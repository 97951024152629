import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
} from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import { makeStyles } from '@mui/styles';
import UsersCombo from '../components/UsersCombo';
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import Button from "components/CustomButtons/Button.js";
import MaterialTable from "material-table";
import moment from 'moment/min/moment-with-locales';
import { downloadCsv } from '../common/sharedFunctions';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        width: '100%',
        marginTop: theme.spacing(1),
        alignContent: 'center',
        borderRadius: "8px",
    },
    container2: {
        width: '50%',
        marginTop: theme.spacing(1),
        backgroundColor: colors.SECONDARY,
        alignContent: 'center',
        borderRadius: "8px",
        marginBottom: "20px"
    },
    container1: {
        backgroundColor: colors.WHITE,
        borderTopLeftRadius:"0px",
        borderTopRightRadius:"0px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        padding:'30px',
        width: '100%',
        top: "19px",
        boxShadow: "4px 4px 6px #9E9E9E"
    },
    title: {
        color: colors.WHITE, 
        padding:'10px'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: colors.BLACK
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: 40,
        borderRadius: "30px",
        backgroundColor: colors.MAIN_COLOR,
        marginTop:'15px',
        color:colors.WHITE
    },
    label: {
        transformOrigin: "top right",
        right: 0,
        left: "auto"
      }, 
    inputRtl: {
        "& label": {
          right: 20,
          left: "auto"
        },
        "& legend": {
          textAlign: "right"
        }
      }
}));

export default function AddMoney(props) {
    const { t, i18n  } = useTranslation();
    const isRTL = i18n.dir();
    const {
        addToWallet,
        addMoney
    } = api;
    const settings = useSelector(state => state.settingsdata.settings);
    const dispatch = useDispatch();
    const classes = useStyles();
    const userdata = useSelector(state => state.usersdata);
    const [users, setUsers] = useState(null);
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
    const [userCombo, setUserCombo] = useState(null);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        if (userdata.users) {
            let arr = [];
            for (let i = 0; i < userdata.users.length; i++) {
                let user = userdata.users[i];
                arr.push({
                    'firstName': user.firstName,
                    'lastName': user.lastName,
                    'mobile': user.mobile,
                    'email': user.email,
                    'uid': user.id,
                    'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin? user.email : "Hidden"),
                    'pushToken': user.pushToken ? user.pushToken : ''
                });
            }
            setUsers(arr);
        }
    }, [userdata.users,settings.AllowCriticalEditsAdmin]);

    const handleAddBalance = () => {
        if(userCombo && userCombo.uid && amount> 0){
            dispatch(addToWallet(userCombo.uid,amount));
            setCommonAlert({ open:true, msg: t('success')});
            let userData = {
                firstName: userCombo.firstName,
                lastName: userCombo.lastName,
                mobile: userCombo.mobile,
                uid: userCombo.uid,
                amount: amount,
                addAt: new Date().getTime()
            };
            dispatch(addMoney(userData));
            setUserCombo(null);
            setAmount(0);
        }else{
            setCommonAlert({ open:true, msg: t('no_details_error')});
        }
    }

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({ open: false, msg: '' })
    };

    const columns =  [
        { title: t('createdAt'), field: 'addAt', editable:'never', defaultSort:'desc',render: rowData =>  rowData.addAt ? moment(rowData.addAt).format('lll') : null ,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},
        { title: t('firstname'), field: 'firstName', editable: 'never', cellStyle:{paddingLeft:isRTL=== 'rtl'?40:null}},
        { title: t('lastname'), field: 'lastName', editable: 'never', cellStyle:{paddingLeft:isRTL=== 'rtl'?40:null}},
        { title: t('mobile'), field: 'mobile', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},
        { title: t('amount'), field: 'amount', editable: 'never', cellStyle:{paddingLeft:isRTL=== 'rtl'?40:null}},
    ];
    const [data, setData] = useState([]);
    const addmoneydata = useSelector(state => state.addmoneydata);
    useEffect(()=>{
        if(addmoneydata.addmoneys){
            setData(addmoneydata.addmoneys);
        }else{
            setData([]);
        }
    },[addmoneydata.addmoneys]);

    const [selectedRow, setSelectedRow] = useState(null);

    return (
        <div className={classes.container} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
            <div className={classes.container2} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid item >
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h5" className={classes.title} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
                                {t('add_to_wallet')}
                            </Typography>
                        </Grid>
                        <div className={classes.container1}>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    {users ?
                                        <UsersCombo
                                            className={classes.items}
                                            placeholder={t('select_user')}
                                            users={users}
                                            value={userCombo}
                                            onChange={(event, newValue) => {
                                                setUserCombo(newValue);
                                            }}
                                            
                                        />
                                        : null}
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        id="datetime-local"
                                        label={t('amount')}
                                        type="number"
                                        variant={"outlined"}
                                        fullWidth
                                        className={isRTL==='rtl'? classes.inputRtl:classes.commonInputStyle}
                                        InputProps={{
                                            className: classes.input
                                        }}
                                        value={amount}
                                        onChange={event => {
                                            const { value } = event.target;
                                            setAmount(value === '' || value === null || value === undefined? 0:parseFloat(value));
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Button
                                        size="lg"
                                        onClick={handleAddBalance}
                                        variant="contained"
                                        color="success"
                                        className={classes.buttonStyle}
                                    >
                                        {t('add_to_wallet')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>

            <MaterialTable
                title={t('add_money')}
                columns={columns}
                style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
                data={data}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                    exportCsv: (columns, data) => {
                    let hArray = [];
                    const headerRow = columns.map(col => {
                        if (typeof col.title === 'object') {
                        return col.title.props.text;
                        }
                        hArray.push(col.field);
                        return col.title;
                    });
                    const dataRows = data.map(({ tableData, ...row }) => {
                        row.addAt = row.addAt? new Date(row.addAt).toLocaleDateString() + ' '+ new Date(row.addAt).toLocaleTimeString(): ''
                        let dArr = [];
                        for(let i=0;i< hArray.length; i++) {
                        dArr.push(row[hArray[i]]);
                        }
                        return Object.values(dArr);
                    })
                    const { exportDelimiter } = ",";
                    const delimiter = exportDelimiter ? exportDelimiter : ",";
                    const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
                    const csvFileName = 'download.csv';
                    downloadCsv(csvContent, csvFileName);
                    },
                    exportButton: {
                    csv: settings.AllowCriticalEditsAdmin,
                    pdf: false,
                    },
                    rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
                    }),
                    editable:{
                    backgroundColor: colors.WHITE,
                    fontSize: "0.8em",
                    fontWeight: 'bold ',
                    fontFamily: 'Lucida Console", "Courier New", monospace'
                    },
                    headerStyle: {
                    position: "sticky",
                    top: "0px",
                    backgroundColor: colors.SECONDARY ,
                    color:  colors.WHITE,
                    fontSize: "0.8em",
                    fontWeight: 'bold ',
                    fontFamily: 'Lucida Console", "Courier New", monospace'
                    }
                }}
                localization={{
                    toolbar: {
                    searchPlaceholder: (t('search')),
                    exportTitle: (t('export')),
                    },
                    header: {
                    actions: (t('actions')) 
                },
                pagination: {
                    labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
                    firstTooltip: (t('first_page_tooltip')),
                    previousTooltip: (t('previous_page_tooltip')),
                    nextTooltip: (t('next_page_tooltip')),
                    lastTooltip: (t('last_page_tooltip'))
                },
                }}
                />
        </div>
    );
}