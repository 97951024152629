import {
    FETCH_ADDMONEY,
    FETCH_ADDMONEY_SUCCESS,
    FETCH_ADDMONEY_FAILED,
  } from "../store/types";
  
  const INITIAL_STATE = {
    addmoneys: null,
    loading: false,
    error: {
      flag: false,
      msg: null
    }
  }
  
  export const addmoneyreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ADDMONEY:
        return {
          ...state,
          loading: true
        };
      case FETCH_ADDMONEY_SUCCESS:
        return {
          ...state,
          addmoneys: action.payload,
          loading: false
        };
      case FETCH_ADDMONEY_FAILED:
        return {
          ...state,
          addmoneys: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload
          }
        };
      default:
        return state;
    }
  };