export const FirebaseConfig = {
	"projectId": "trasladex-1701153940063",
	"appId": "1:219002034833:web:3dca403fd7e9a0235dfd30",
	"databaseURL": "https://trasladex-1701153940063-default-rtdb.firebaseio.com",
	"storageBucket": "trasladex-1701153940063.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCHBPd7YIQ1GKyw9odWtexLNbcKUTpr5sM",
	"authDomain": "trasladex-1701153940063.firebaseapp.com",
	"messagingSenderId": "219002034833",
	"measurementId": "G-B41WMYXWNY"
};