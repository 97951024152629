import {
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILED,
    EDIT_CATEGORY
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, remove, push } from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchCategories = () => (dispatch) => {
    const {
        categoryRef
    } = firebase;
    dispatch({
      type: FETCH_CATEGORIES,
      payload: null
    });
    onValue(categoryRef, snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_CATEGORIES_SUCCESS,
          payload: arr.filter(cat=>cat.enable === true)
        });
      } else {
        dispatch({
          type: FETCH_CATEGORIES_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    });
  };
  
  export const editCategory = (cat, method) => async (dispatch) => {
    const {
      categoryRef, 
      categoryEditRef,
      categoryImage
    } = firebase;
    dispatch({
      type: EDIT_CATEGORY,
      payload: { method, cat }
    });
    if (method === 'Add') {
      push(categoryRef, cat);
    } else if (method === 'Delete') {
      remove(categoryEditRef(cat.id));
    } else if (method === 'UpdateImage') {
      await uploadBytesResumable(categoryImage(cat.id),cat.cat_image);
      let image = await getDownloadURL(categoryImage(cat.id));
      let data = cat;
      data.cat_image = image;
      update(categoryEditRef(cat.id), data);
    }
    else {
      update(categoryEditRef(cat.id),cat);
    }
  }