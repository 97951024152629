import { 
    UPDATE_USER_WALLET_HISTORY
} from "../store/types";
    
export const INITIAL_STATE = {
    walletHistory:null
}

export const wallethistoryreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case UPDATE_USER_WALLET_HISTORY:
    return {
        ...state,
        walletHistory:action.payload
    };
    default:
        return state;
    }
};