import {
    CONFIRM_BOOKING,
    CONFIRM_BOOKING_SUCCESS,
    CONFIRM_BOOKING_FAILED,
    CLEAR_BOOKING
} from "../store/types";
import { RequestPushMsg } from '../other/NotificationFunctions';
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, push } from "firebase/database";

export const clearBooking = () => (dispatch) => {
    dispatch({
        type: CLEAR_BOOKING,
        payload: null,
    });
}

export const addBooking = (bookingData) => async (dispatch) => {

    const   {
        bookingRef,
        config,
        singleUserRef
    } = firebase;

    dispatch({
        type: CONFIRM_BOOKING,
        payload: bookingData,
    });

    const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const reference = [...Array(6)].map(_ => c[~~(Math.random()*c.length)]).join('');

    let today;
    try{
        let res =  await fetch(`https://${config.projectId}.web.app/getservertime`, { method: 'GET', headers: {'Content-Type': 'application/json'}});
        const json = await res.json();
        if(json.time){
          today = json.time;
        } else{
          today = new Date().getTime();
        }
    }catch (err){
      today = new Date().getTime();
    }

    let pickUp = { lat: bookingData.pickup.coords.lat, lng: bookingData.pickup.coords.lng, add: bookingData.pickup.description };
    let drop = { lat: bookingData.drop.coords.lat, lng: bookingData.drop.coords.lng, add: bookingData.drop.description };

    let coords = [{latitude: pickUp.lat,longitude: pickUp.lng}];
    if(bookingData.drop.waypointsStr){
        bookingData.drop.waypointsStr.split("|").forEach((point)=>{
            let lat = point.split(',')[0];
            let lng = point.split(',')[1];
            coords.push({latitude: parseFloat(lat), longitude: parseFloat(lng)});
        });
    }
    coords.push({latitude: drop.lat,longitude: drop.lng});

    var otp;
    if(bookingData.settings.otp_secure)
        otp = Math.floor(Math.random() * 90000) + 10000;
    else{
        otp = false;
    }

    let data = {
        category: bookingData.carDetails.category,
        currency: bookingData.userDetails.currency_code,
        country: bookingData.userDetails.country_code,
        hasBid: bookingData.carDetails.hasBid,
        carType: bookingData.carDetails.name,
        carImage: bookingData.carDetails.image,
        customer: bookingData.userDetails.uid,
        fleet_admin_comission: bookingData.carDetails.fleet_admin_fee ? bookingData.carDetails.fleet_admin_fee : null,
        commission_type: bookingData.carDetails.convenience_fee_type,
        commission_rate: bookingData.carDetails.convenience_fees,
        reference: reference,
        customer_email: bookingData.userDetails.email,
        customer_name: bookingData.userDetails.firstName + ' ' + bookingData.userDetails.lastName,
        customer_contact: bookingData.userDetails.mobile? bookingData.userDetails.mobile: ' ',
        customer_token: bookingData.userDetails.pushToken? bookingData.userDetails.pushToken: ' ',
        customer_image: bookingData.userDetails.profile_image ? bookingData.userDetails.profile_image : "",
        drop: drop,
        pickup: pickUp,
        estimate: bookingData.estimate.estimateFare,
        estimateDistance: bookingData.estimate.estimateDistance,
        distance: bookingData.estimate.estimateDistance,
        estimateTime:bookingData.estimate.estimateTime,
        status: bookingData.booking_type_admin || bookingData.payment_mode=='cash' || bookingData.carDetails.hasBid? "NEW" : "PAYMENT_PENDING",
        bookLater:bookingData.bookLater,
        tripdate: bookingData.bookLater?bookingData.tripdate:today,
        bookingDate: today,
        otp: otp,
        booking_type_admin:bookingData.booking_type_admin,
        coords: coords,
        waypoints: bookingData.drop.waypoints? bookingData.drop.waypoints: null,
        tripInstructions: bookingData.tripInstructions? bookingData.tripInstructions: null,
        trip_cost: bookingData.estimate.estimateFare,
        convenience_fees: bookingData.estimate.convenience_fees,
        driver_share: (parseFloat(bookingData.estimate.estimateFare) - parseFloat(bookingData.estimate.convenience_fees)).toFixed(bookingData.settings.decimal),
        paymentPacket: bookingData.paymentPacket? bookingData.paymentPacket : null,
        requestedDrivers: bookingData.requestedDrivers?  bookingData.requestedDrivers: null,
        driverEstimates: bookingData.driverEstimates?  bookingData.driverEstimates: null,
        ...bookingData.instructionData,
        payment_mode: bookingData.payment_mode,
        booking_from_web: bookingData.booking_from_web? bookingData.booking_from_web: false,
        fleetadminrider: bookingData.fleetadminrider ? bookingData.fleetadminrider: null,
        fleetadmin: bookingData.fleetadmin? bookingData.fleetadmin: null,
        bidPrice: bookingData.bidPrice? bookingData.bidPrice: null,
        bidPriceData: bookingData.bidPriceData? bookingData.bidPriceData: {},
        negotiations: bookingData.negotiations ? bookingData.negotiations: {}
    }
    // the above line 111 and 112 is added coz of rebid plugin
    // bidPrice: bookingData.bidPrice? bookingData.bidPrice: null
    //
    if(bookingData.requestedDrivers){
        const drivers = bookingData.requestedDrivers;
        Object.keys(drivers).map((uid)=>{
            onValue(singleUserRef(uid),  snapshot => {
                if (snapshot.val()) {
                    const pushToken = snapshot.val().pushToken;
                    const ios = snapshot.val().userPlatform == "IOS"? true: false
                    if(pushToken){
                        RequestPushMsg(
                            pushToken,
                            {
                                title: store.getState().languagedata.defaultLanguage.notification_title,
                                msg: store.getState().languagedata.defaultLanguage.new_booking_notification,
                                screen: 'DriverTrips',
                                channelId: bookingData.settings.CarHornRepeat? 'bookings-repeat': 'bookings',
                                ios: ios
                            }
                        );
                    }
                }
            }, {onlyOnce: true});
            return drivers[uid];
        })
    }

    push(bookingRef, data).then((res) => {
        var bookingKey = res.key;
        dispatch({
            type: CONFIRM_BOOKING_SUCCESS,
            payload: {
                booking_id:bookingKey,
                mainData:{
                    ...data,
                    id:bookingKey
                }
            }
        });
    }).catch(error => {
        dispatch({
            type: CONFIRM_BOOKING_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
};

